import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout
    style={{
      fontFamily: `Brandon Text`,
      fontWeight: 400,
      lineHeight: 1.4,
      color: `#393939`,
    }}
  >
    <SEO
      title="Resume"
      keywords={[`resume`, `application`, `Edmunds`, `thomasliu`]}
    />

    <section className="row">
      <div className="col-3">
        <div className="background-info">
          <h2>Contact</h2>
          <p>
            <a href="mailto:thomasl@hey.com">thomasl@hey.com</a>
          </p>
          <p>
            <a href="tel:2133080159">(213)308-0159</a>
          </p>
          <p>Los Angeles, CA</p>
          <p>
            <a href="/" target="__blank" rel="noopener">
              edmunds.thomasliu.com
            </a>
          </p>
        </div>
        <div className="background-info">
          <h2>Education</h2>
          <p>
            University of California, Irvine <br />
            B.S. Computer Science
          </p>
        </div>
        <div className="background-info">
          <h2>Languages + Tools</h2>
          <p>
            PHP, Python, Java, <br />
            Javascript, C++, MySQL,
            <br />
            Node.js, Apache, Git
          </p>
        </div>
        <div className="background-info">
          <h2>Operating Systems</h2>
          <p>
            Linux, Unix <br />
            OSX <br />
            Windowx 7/10
          </p>
        </div>
      </div>
      <div className="col-9 experience">
        <section>
          <h2>Experience</h2>
          <div className="experience__details">
            <h3>Alltrue (formerly CauseBox) - Software Engineer</h3>
            <p>Oct 2021 - Current</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Creating new e-commerce features within tight deadlines and
                quickly iterating on new changes.
                <br />
                - Identify potential performance gains and bottlenecks and
                coming up with solutions to resolve them.
                <br />
                - Leading and delivering projects with minimal guidance.
                <br />
                - Worked closely with product design and other stakeholders to
                discuss tradeoffs from start to finish.
                <br />- Conducted code reviews and provided feedback.
              </li>
              <li>
                Technologies: PHP, Vue.js, Figma, Docker, Storybook.js,
                Cypress.js, webpack, MySQL, Shortcut, Github, Cloudflare,
                Datadog, CGP
              </li>
            </ul>
          </div>
        </section>
        <section>
          <div className="experience__details">
            <h3>Brandastic - Developer</h3>
            <p>2017 - Oct 2021</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Developed websites and applications using a variety of the
                WordPress platform, custom PHP applications, and SPA (React).
                <br />
                - Managed and monitored production environments for multiple
                applications.
                <br />
                - Automated application deployments. Reduced risk and time
                required to deploy to production.
                <br />- Worked closely with clients and the project management
                team to gather requirements, create timeline estimates, and keep
                projects on track.
              </li>
              <li>
                Technologies: PHP, React.js, Javascript(ES6), SCSS/CSS, gulp,
                webpack, MySQL, Ubuntu, Centos, Linode, JIRA, Bitbucket,
                Cloudflare, Confluence, deploybot
              </li>
            </ul>
          </div>
        </section>
        <section>
          <h2>Past Projects</h2>
          <div className="experience__details">
            <h3>Gatsby.js</h3>
            <p>2019</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                This resume is built with Gatsby.js and hosted on Netlify. It is
                mobile friendly and print friendly.
              </li>
            </ul>
          </div>

          <div className="experience__details">
            <h3>Node.js</h3>
            <p>2016</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Image search abstraction layer powered by Bing’s image search
                API. Simplifies searches for the user and stores the most recent
                search results.
                <br />
                - URL shortening service API. Takes a URL as the parameter and
                returns a shorter version where the user will be redirected when
                requesting the short URL.
                <br />
                - Request header parser API. Accepts a user’s get request and
                returns their IP address, browser, language, browser type and
                operating system back to the user in a JSON object.
                <br />- Timestamp microservice API. Accepts a Unix timestamp or
                a natural language date as a URL parameter and returns both
                results in a JSON object.
              </li>
            </ul>
          </div>

          <div className="experience__details">
            <h3>Java</h3>
            <p>2016</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Full stack IMDB website called Fabflix using Java
                Servlets/JSP, JDBC/MySql, Tomcat Apache and deploying it onto an
                AWS instance.
                <br />
                - Create a fake programming language called Crux. Scanning and
                parsing used a LL(1) parsing method.
                <br />- File system using blocks to simulate a disk, open file
                tables to act as buffers for reading and writing consecutive
                blocks to the disk, and a bit map to keep track of the blocks
                used.
              </li>
            </ul>
          </div>

          <div className="experience__details">
            <h3>C++</h3>
            <p>2013</p>
          </div>
          <div className="experience__descriptions">
            <ul>
              <li>
                - Hash table class as a well-behaved class to store usernames
                and passwords. Requirements included manual garbage collection,
                load factoring, and rehashing without the use of vectors, lists,
                or libraries to handle memory reallocations.
                <br />- AI for playing the game of Othello/Reversi using a
                min-max algorithm and a heuristic depthfirst-search.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
